import AdminLayout from '../../Layout/Admin/Admin'
import './Users.scss'

const Users = () => {
    return <AdminLayout>
        <div id="admin-users">
            to be implemented
        </div>
    </AdminLayout>
}

export default Users